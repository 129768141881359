<template>
  <div class="addchamber">
    <div class="essential_information">
      <h4>基本信息</h4>
      <div class="form_essential">
        <el-form ref="form" :model="form" :rules="rules" label-width="110px">
          <el-form-item label="商会头像:" prop="chamberCommerceLogo">
            <img-big-upload
              :imageShow.sync="form.chamberCommerceLogo"
              :addTitleShow="false"
              @removeImg="removeImgDians"
              @uploadOneImgShow="uploadOneImgShowDians"
            />
          </el-form-item>
          <el-form-item label="商会名称:" prop="chamberCommerceName">
            <el-input v-model="form.chamberCommerceName" placeholder="请输入商会名称" />
          </el-form-item>

          <el-form-item label="所在地区:" prop="city">
            <div class="select">
              <el-select v-model="form.province" placeholder="请选择省份" @change="citychange">
                <el-option
                  v-for="item in operatingList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
              <div style="display: inline-block; width: 10px"></div>
              <el-select
                v-model="form.city"
                placeholder="请选择市"
               
                @focus="citychange"
              >
                <el-option
                  v-for="item in operatingLists"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            
            </div>
          </el-form-item>

          <el-form-item label="成立日期:" prop="establishmentDate">
            <div class="date_picker">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="form.establishmentDate"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item label="商会简介:" prop="chamberCommerceIntro">
            <el-input
              v-model="form.chamberCommerceIntro"
              type="textarea"
              placeholder="请输入商会简介"
            />
          </el-form-item>
          <el-form-item label="关联地区:" prop="associationArea">
            <el-input v-model="form.associationArea" placeholder="请输入关联地区" />
          </el-form-item>
          <div class="applets_share">
            <h4>小程序分享链接信息</h4>
            <el-form-item label="分享链接标题:" prop="">
              <el-input v-model="form.shareTitle" placeholder="请输入分享链接标题" />
            </el-form-item>
            <el-form-item label="分享链接图片:" prop="">
              <div class="com_upload">
                <img-big-upload
                  :imageShow.sync="form.sharePictures"
                  :addTitleShow="false"
                  @removeImg="removeImgDian"
                  @uploadOneImgShow="uploadOneImgShowDian"
                />
              </div>
              <div class="img_tips">
                <span>(建议整体尺寸为630*510px，图片格式应为jpg、jpeg、png，图片应小于12MB)</span>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>

    <div style="margin-top: 30px" slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">保存</el-button>
      <el-button @click="clcans">取消</el-button>
    </div>
  </div>
</template>

<script>
import { getByFatherIdt, getProvince } from '@/api/demand'
import { addcommerce, editcommerce, getcommercet } from '@/api/chambercenter'
export default {
  name: 'addchamber',
  computed: {
    id() {
      return this.$route.query.id * 1
    }
  },
  data() {
    return {
      form: {
        associationArea: null,
        chamberCommerceIntro: null,
        chamberCommerceLogo: '',
        chamberCommerceName: null,
        city: null,
        createId: null,
        createTime: null,
        district: null,
        establishmentDate: null,
        id: null,
        province: null,
        sharePictures: '',
        shareTitle: null,
        updateId: null,
        updateTime: null
      }, //表单收集

      queryInfo: {},
      operatingList: [], //所有省
      operatingLists: [], //所有市
     
      rules: {
        chamberCommerceLogo: [{ required: true, message: '请上传商会头像', trigger: 'blur' }],
        chamberCommerceName: [{ required: true, message: '请输入商会名称', trigger: 'blur' }],
        city: [{ required: true, message: '请选择所在地区', trigger: 'change' }],
        establishmentDate: [{ required: true, message: '请选择成立日期', trigger: 'change' }],
        chamberCommerceIntro: [{ required: true, message: '请输入商会简介', trigger: 'blur' }],
        associationArea: [{ required: true, message: '请输入关联地区', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.incity()
    if (this.id) {
      this.getcommercet()
    }
  },
  methods: {
    //获取商会详情
    async getcommercet() {
      const res = await getcommercet(this.id)
      if (res.data.resultCode == 200) {
        this.form = res.data.data
        this.operatingList.forEach((o) => {
          if (this.form.province == o.name) {
            this.getByFatherIdt(o.code)
          }
        })
      }
    },

    //进去城市
    async getByFatherIdt(id) {
      const res = await getByFatherIdt({ provinceCode: id })
      if (res.data.resultCode == 200) {
        this.operatingLists = res.data.data
      }
    },
    //城市
    async incity() {
      const res = await getProvince()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },
 
    //确认选择省
    citychange() {
      this.operatingList.forEach((o) => {
        if (this.form.province == o.name) {
          this.form.city = ''
          this.getByFatherIdt(o.code)
        }
      })
    },
   
    //获取长地址图片
    uploadOneImgShowDian(val) {
      this.form.sharePictures = val
    },
    /**
     * 删除图
     */
    removeImgDian(val) {
      this.form.sharePictures = ''
    },
    //获取长地址图片
    uploadOneImgShowDians(val) {
      this.form.chamberCommerceLogo = val
    },
    /**
     * 删除图
     */
    removeImgDians(val) {
      this.form.chamberCommerceLogo = ''
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.form.id == null) {
            addcommerce(this.form).then((response) => {
              this.$message.success('保存成功')
              this.$router.go(-1)
            })
          } else {
            editcommerce(this.form).then((response) => {
              this.$message.success('保存成功')
              this.$router.go(-1)
            })
          }
        }
      })
    },
    clcans() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .essential_information .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 596px;
  height: 100px;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

::v-deep .essential_information .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 596px;
}

::v-deep .essential_information .select .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 292px;
}
::v-deep .date_picker .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 30px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 596px;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 560px;
}
.addchamber {
  width: 100%;
  background: #ffffff;
  padding: 10px 35px;

  .img_tips {
    position: relative;
    top: -10px;
    color: #6ba1ff;
  }

  .essential_information {
    h4 {
      color: #146aff;
    }
  }

  .label_management {
    padding: 30px 10px;

    .new_page {
      margin: 20px auto;
      width: 100%;
      text-align: center;
    }

    .input-with {
      width: 100%;
      overflow: hidden;
      padding: 20px 0px;

      .input-with-select {
        width: 400px;
        float: right;
      }

      .seach {
        background-color: #448aff;
        color: #fff;
        border-radius: 0;
        border: 1px solid #448aff;
      }
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
  }
}
</style>
